import React from "react";
import fadeWhenScroll from "common/fadeWhenScroll";

const BlogHeader = ({ title = '', description = '', philigram = '', sliderRef }) => {
  React.useEffect(() => {
    fadeWhenScroll(document.querySelectorAll(".fixed-slider .capt .parlx"));
  }, []);

  return (
    <header ref={sliderRef} className="works-header fixed-slider hfixd valign sub-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-9 static">
            <div className="capt mt-50">
              <div className="parlx text-center">
                <h2 className="color-font">{title}</h2>
                <p>{description}</p>
              </div>

              <div className="bactxt custom-font valign">
                <span className="full-width">{philigram}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default BlogHeader;
