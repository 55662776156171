"use client"
import React from "react";
import DarkTheme from "layouts/Dark";
// import { StoryblokComponent, useStoryblokState } from "gatsby-source-storyblok";
import accommodations from "data/sections/accommodations.json";
import NavbarLR from "components/NavbarLR/navbar";
import Footer from "components/Footer/footer";
import BlogHeader from "components/Blog-Header/blog-header";
import initIsotope from "common/initIsotope";

const Accommodations = ({ data }) => {
  // let story = data.storyblokEntry
  // story = useStoryblokState(story)

  // const components = story.content.body.map(blok => (<StoryblokComponent blok={blok} key={blok._uid} />))
  const fixedHeader = React.useRef(null);
  const MainContent = React.useRef(null);
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    setTimeout(() => {
      initIsotope();
    }, 1000);
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      if (fixedHeader.current) {
        var slidHeight = fixedHeader.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);

    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [fixedHeader, MainContent, navbarRef]);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 150) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 150) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);
  const grid = 3;

  return (
    <DarkTheme mobileappstyle={true}>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>

      <NavbarLR nr={navbarRef}  lr={logoRef}/>
      <BlogHeader sliderRef={fixedHeader} title="Accommodation" philigram="Sleep" />

      <div ref={MainContent} className="main-content">
        <section className={`${grid ? (grid === 3 ? "three-column" : null) : null} portfolio section-padding pb-70`}>
        <div className="container">
        <div className="row">
        <div className="gallery full-width">
          {accommodations.map((a, ind) => {

            return <div className={`${grid === 3 ? "col-lg-4 col-md-6" : grid === 2 ? "col-md-6" : "col-12"} items ${['graphic', 'web', 'brand'][ind % 3]} wow fadeInUp`} data-wow-delay=".4s">
              <div className="item-img" style={{
                borderRadius: '15px'
              }}>
                <a href={a.link} target="blank" className="imago wow">
                  <img src={a.image} alt={a.name} />
                  <div className="item-img-overlay"></div>
                </a>
              </div>
              <div className="cont">
                <h6>{a.name}</h6>
              </div>
            </div>
          })}
        </div>
        </div>
        </div>
        </section>
      </div>
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Little River Music Festival - Accommodations</title>
      <meta property="og:title" content="Little River Music Festival - Accommodations" />
      <meta property="og:description" content="Explore the best accommodations in Little River for the upcoming Music Festival. Find cozy hotels, B&Bs, and rentals close to the event for a memorable stay." />
      <meta property="og:image" content="https://festival.littleriver.co.nz/img/meta/accommodation-meta.jpg" />
      <meta property="og:type" content="article" />
      <meta property="og:url" content="https://festival.littleriver.co.nz/about/accommodations/" />
    </>
  )
}

export default Accommodations;
